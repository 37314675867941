import { React, useState, useEffect } from 'react'

import axios from 'axios'

//material ui
import { Divider, Grid, Button, TextField, Typography } from '@mui/material'

export default function LabNewTestS5({ testSet, onNext }) {
  const [state, setState] = useState('ready')
  const [message, setMessage] = useState('')
  const [testSummary, setTestSummary] = useState('')

  useEffect(() => {
    let summary =
      'Protocol: ' + (testSet.type ? 'Testosteron' : 'Cortisol') + '\n\n'

    summary += testSet.results.summary

    summary +=
      '\n\nBatches: ' + testSet.batches.map((batch) => batch.id + ' ') + '\n\n'

    summary += 'Comments:\n' + testSet.comments

    setTestSummary(summary)
  }, [testSet])

  const onClick = () => {
    switch (state) {
      case 'ready':
        setMessage('Uploading test...')
        setState('uploading')

        let body = {
          ...testSet,
        }

        axios
          .post('/test', body)
          .then((id) => {
            setMessage('Assigning results to batch(es)....')

            axios.get('/tests/assign/' + id.data).then(() => {
              setMessage('Test uploaded succesfully.')
              setState('uploaded')
            })
          })
          .catch(() => {
            setMessage('There was an error while uploading test')
            setState('uploaded')
          })
        break
      case 'uploaded':
        onNext()
        break
      default:
    }
  }

  return (
    <>
      <Divider />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <TextField
            label="Summary"
            multiline
            fullWidth
            disabled
            rows={20}
            style={{ margin: '20px auto 20px auto' }}
            value={testSummary}
          />
          <Typography variant="body1" style={{ margin: '40px auto 40px auto' }}>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Divider />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onClick}
        disabled={state === 'uploading'}
        style={{ margin: '20px auto 20px 40px' }}
      >
        {state === 'ready' && 'Upload'}
        {state === 'uploaded' && 'Close'}
        {state === 'uploading' && 'Close'}
      </Button>
    </>
  )
}
